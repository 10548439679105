import { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { StaticTextInputProps } from './StaticTextInput.types';

export const StaticTextInputStyles = css<{
  invalid: boolean;
  isWhiteBackground: StaticTextInputProps['isWhiteBackground'];
}>`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 14px 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black};
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s;

  &::placeholder,
  &:disabled {
    color: ${(props) => props.theme.black100_alpha50};
    opacity: 1;
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${colors.red600};
    `}

  ${({ isWhiteBackground }) =>
    isWhiteBackground &&
    css`
      border: 1px solid ${(props) => props.theme.gray1800};
    `}
`;
