import { sum } from 'lodash';

export interface Point {
  latitude: number;
  longitude: number;
}

export interface MapProps {
  center: { lat: number; lng: number };
  zoom: number;
}

export const getMapProps = (points: Point[]): MapProps => {
  const pointsLength = points.length;
  const averageLatitude =
    sum(points.map(({ latitude }) => latitude)) / pointsLength;
  const averageLongitude =
    sum(points.map(({ longitude }) => longitude)) / pointsLength;

  return {
    center: {
      lat: averageLatitude,
      lng: averageLongitude,
    },
    zoom: 11,
  };
};
