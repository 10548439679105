import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Loader from '@/components/Loader';
import { CheckboxAgreement } from '@/components/Checkbox';
import StaticTextInput from '@/components/StaticTextInput';
import StaticTextArea from '@/components/StaticTextArea';
import SuccessForm from '@/components/Forms/SuccessForm';
import { ServiceFormSecondaryFields } from '@/components/LandingWidgets/components/ServiceFormSecondary/types';
import Button from '@/components/Button';

import { ServiceFormPrimaryFields, ServiceFormPrimaryProps } from './types';
import {
  initialServiceFormPrimaryValues,
  validationServiceFormPrimarySchema,
} from './helpers';

export function ServiceFormPrimary({
  title,
  buttonTitle,
  onSubmit,
}: ServiceFormPrimaryProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
  } = useFormik<ServiceFormPrimaryFields>({
    initialValues: initialServiceFormPrimaryValues,
    validationSchema: validationServiceFormPrimarySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ServiceFormSecondaryFields>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete="off">
      {!isFormSend ? (
        <React.Fragment>
          <Title>{title}</Title>

          <Fields>
            <FieldsRow>
              <Input>
                <StaticTextInput
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Имя"
                  errorMessage={
                    errors.name && touched.name ? errors.name : undefined
                  }
                  isWhiteBackground
                />
              </Input>

              <Input>
                <StaticTextInput
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="Телефон"
                  errorMessage={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                  isWhiteBackground
                />
              </Input>
            </FieldsRow>

            <TextArea>
              <StaticTextArea
                id="message"
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Комментарий"
                errorMessage={
                  errors.message && touched.message ? errors.message : undefined
                }
                isWhiteBackground
              />
            </TextArea>
          </Fields>

          <CheckBoxWrapper>
            <CheckboxAgreement
              checked={userAgreement}
              onChange={() => setUserAgreement(!userAgreement)}
            />
          </CheckBoxWrapper>

          <ButtonWrapper>
            <SubmitButton
              type="submit"
              variant="contained"
              disabled={!isSubmitting ? !userAgreement : true}
            >
              {!isSubmitting ? buttonTitle : <Loader color={colors.white} />}
            </SubmitButton>
          </ButtonWrapper>
        </React.Fragment>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

const Component = styled.form``;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const Fields = styled.div`
  margin-top: 20px;
`;

const FieldsRow = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Input = styled.div`
  width: 100%;

  &:not(:first-child) {
    margin-left: 30px;

    ${media.tablet(css`
      margin-left: 0;
      margin-top: 30px;
    `)}
  }
`;

const TextArea = styled.div`
  margin-top: 30px;
`;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`;

const SubmitButton = styled(Button)`
  max-width: 289px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;
