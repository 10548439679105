import React from 'react';
import styled from 'styled-components';

import { MapProps } from './types';
import GoogleMap from './components/GoogleMap';
import YandexMap from './components/YandexMap';

function Map({ markers, mapType = 'google' }: MapProps) {
  if (!markers || markers.length === 0) {
    return null;
  }

  return (
    <Component>
      {mapType === 'google' ? (
        <GoogleMap markers={markers} />
      ) : (
        <YandexMap markers={markers} />
      )}
    </Component>
  );
}

export default Map;

const Component = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
