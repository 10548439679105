import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ButtonNextLink } from '@/components/Button';

import { NewsProps } from './types';
import NewsList from './components/NewsList';
import NewsSlider from './components/NewsSlider';

export function News({ id, news, buttonText, buttonLink }: NewsProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!news || news.length === 0) {
    return null;
  }

  return (
    <Component>
      {!isMobile ? (
        <NewsList news={news} />
      ) : (
        <NewsSlider id={id} news={news} />
      )}

      <ButtonWrapper>
        <StyledButtonLink variant="outlined" to={buttonLink}>
          {buttonText}
        </StyledButtonLink>
      </ButtonWrapper>
    </Component>
  );
}

const Component = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 170px;
`;
