import React from 'react';
import styled, { css } from 'styled-components';

import { AboutCardSecondaryBenefit } from '@/components/LandingWidgets/components/AboutCardSecondary/components/AboutCardSecondaryBenefit';

import { AboutCardSecondaryBenefitListProps } from './types';

function AboutCardSecondaryBenefitList({
  benefits,
}: AboutCardSecondaryBenefitListProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component benefitsCount={benefits.length}>
      {benefits.map((props, index) => (
        <AboutCardSecondaryBenefit key={index} {...props} />
      ))}
    </Component>
  );
}

export default AboutCardSecondaryBenefitList;

const Component = styled.div<{ benefitsCount: number }>`
  display: grid;
  gap: 20px;

  ${({ benefitsCount }) =>
    benefitsCount > 5
      ? css`
          grid-template-columns: repeat(5, 1fr);
        `
      : css`
          grid-template-columns: repeat(${benefitsCount}, 1fr);
        `}
`;
