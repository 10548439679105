import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

import { BannersSliderSecondaryProps } from './types';

SwiperCore.use([Autoplay, Pagination]);

export function BannersSliderSecondary({
  id,
  banners,
}: BannersSliderSecondaryProps) {
  if (!banners || banners.length === 0) {
    return null;
  }

  const isOneBanner = banners.length <= 1;
  const paginationClassname = `swiper-pagination${id ?? ''}`;

  return (
    <Component>
      <Container>
        <Swiper
          slidesPerView="auto"
          touchMoveStopPropagation={true}
          speed={1250}
          allowTouchMove={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          pagination={{
            el: `.${paginationClassname}`,
            type: 'bullets',
            clickable: true,
          }}
          noSwipingClass={'swiper-no-swiping'}
        >
          {banners.map(
            ({ image, url, mobileImage, title, description }, index) => {
              const hasContent = title || description;

              return (
                <SwiperSlide
                  key={index}
                  className={isOneBanner ? 'swiper-no-swiping' : ''}
                >
                  <SlideItem>
                    <SlidePicture
                      loading="lazy"
                      desktop={convertThumbnailToPictureImage(image)}
                      laptop={convertThumbnailToPictureImage(image)}
                      tabletSmall={convertThumbnailToPictureImage(
                        mobileImage ?? image
                      )}
                      mobile={convertThumbnailToPictureImage(
                        mobileImage ?? image
                      )}
                      alt={title ? title : `banner image ${index + 1}`}
                    />
                    {hasContent && (
                      <SlideContent>
                        <SliderInner>
                          {title && <SliderTitle>{title}</SliderTitle>}
                          {description && (
                            <SliderDescription>{description}</SliderDescription>
                          )}
                        </SliderInner>
                      </SlideContent>
                    )}
                    {url && <SlideLink to={url} />}
                  </SlideItem>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>

        <PaginationWrapper className={paginationClassname} />
      </Container>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 23.438%;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${media.tablet(css`
    padding-top: 41.668%;
  `)}
`;

const SlideItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const SlidePicture = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const SlideLink = styled(Link)`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SlideContent = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

const SliderInner = styled.div`
  padding: 0 40px;

  ${media.tablet(css`
    max-width: 50%;
    padding: 0 20px;
    margin: initial;
  `)}
`;

const SliderTitle = styled.h1`
  font-weight: 700;
  font-size: 70px;
  line-height: 79px;
  text-transform: uppercase;
  margin: 0;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 5vw;
    line-height: 130%;
  `)}
`;

const SliderDescription = styled.p`
  position: relative;
  font-size: 25px;
  line-height: 32px;
  margin-top: 50px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 3.5vw;
    line-height: 130%;
    margin-top: 15px;
  `)}

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    width: 100px;
    height: 4px;
    background: ${colors.main};

    ${media.tablet(css`
      top: -10px;
      width: 50px;
    `)}
  }
`;

const PaginationWrapper = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 14px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;

  .swiper-pagination-bullet {
    cursor: pointer;
    opacity: 1;
    width: 8px;
    height: 8px;
    background: ${colors.gray1100};

    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${colors.main};
  }

  ${media.tablet(css`
    bottom: -20px;
  `)}
`;
