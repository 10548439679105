import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import {
  formatNumber,
  isBrowser,
  isNotNullish,
  useMedia,
} from '@tager/web-core';

import { ComponentViewProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import CatalogCardSlider from '@/components/CatalogCarCard/components/CatalogCardSlider';
import ArrowRightIcon from '@/assets/svg/more_details_arrow_right.svg';
import Share, { DropDownMenu } from '@/components/Share';
import MobileCatalogCardSlider from '@/components/CatalogCarCard/components/MobileCatalogCardSlider';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import InfoIcon from '@/assets/svg/info.svg';
import Button, { ButtonNextLink } from '@/components/Button';

function CarPortraitView({
  view,
  link,
  name,
  button,
  images,
  badge,
  badgeHint,
  price,
}: ComponentViewProps) {
  const [currentColor, setCurrentColor] = useState(images[0]?.color);
  const isMobile = useMedia('(max-width: 1259px)');

  const carImages =
    images.find((image) => image?.color?.value === currentColor?.value)
      ?.items ?? (images && images.length > 0 ? images[0].items : []);

  const colors =
    images && images.length > 0
      ? images.map((image) => image?.color).filter(isNotNullish)
      : [];

  return (
    <Component>
      {isMobile ? (
        <MobileCatalogCardSlider
          images={carImages}
          link={link.url}
          view={view}
          badge={badge}
          badgeHint={badgeHint}
          alt={`${name} ${currentColor?.label ?? ''}`.trim()}
        />
      ) : (
        <CatalogCardSlider
          images={carImages}
          view={view}
          link={link.url}
          badge={badge}
          badgeHint={badgeHint}
          alt={`${name} ${currentColor?.label ?? ''}`.trim()}
        />
      )}

      <CardInfo>
        <CardInfoItem>
          <TitleWrapper>
            <CardTitle to={link.url}>{name}</CardTitle>
          </TitleWrapper>

          <AvailableColors>
            {colors &&
              colors.length > 0 &&
              colors.map((item, index) => (
                <AvailableColorsItem
                  key={index}
                  item={item.value}
                  isActive={item.value === currentColor?.value}
                  onClick={() => setCurrentColor(item)}
                />
              ))}
          </AvailableColors>
        </CardInfoItem>
        <CardInfoItem>
          {price ? (
            <MinPrice>
              <Price>
                ОТ <span>{formatNumber(price)}</span> BYN
              </Price>

              <Info>
                <Tippy
                  content={<PriceInfo>Цена на авто в наличии</PriceInfo>}
                  placement="bottom"
                  interactive
                  appendTo={isBrowser() ? document.body : 'parent'}
                >
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                </Tippy>
              </Info>
            </MinPrice>
          ) : null}

          {button.onClick ? (
            <StyledButton variant="contained" onClick={button.onClick}>
              {button.label}
            </StyledButton>
          ) : (
            <StyledButtonLink variant="contained" to={button.url}>
              {button.label}
            </StyledButtonLink>
          )}
          <AboutModel to={link.url}>
            {link.label}
            <Icon>
              <ArrowRightIcon />
            </Icon>
          </AboutModel>
        </CardInfoItem>

        <ShareWrapper className="disabled-modal">
          <Share path={link.url} image={carImages[0]?.url} />
        </ShareWrapper>
      </CardInfo>
    </Component>
  );
}

export default CarPortraitView;

const Component = styled.div`
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled(Link)`
  color: ${(props) => props.theme.black};
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;

  &:hover {
    color: ${colors.main100};
  }

  &::after {
    content: '';
    background: ${(props) => props.theme.whiteGradient};
    right: 0;
    top: 0;
    bottom: 0;
    width: 147px;
    height: 100%;
    display: block;
    position: absolute;
    pointer-events: none;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const AvailableColors = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const AvailableColorsItem = styled.div<{
  item: string;
  isActive?: boolean;
}>`
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px #efefef solid;
  margin-bottom: 10px;
  
  &:not(:last-child) {
    margin-right: 3px;
  }
  
  ${({ item }) =>
    css`
      background: ${item};
    `}
  
  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: ${(props) => props.theme.blackShadow};
    `}
}
`;

const CardInfo = styled.div`
  position: relative;
  background: ${(props) => props.theme.white_1};
  padding: 20px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const CardInfoItem = styled.div``;

export const MinPrice = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.black};

  span {
    font-size: 25px;
  }
`;

export const Info = styled.div`
  margin-left: 3px;
`;

const StyledButton = styled(Button)`
  font-weight: 700;
  margin-top: 20px;
`;

const StyledButtonLink = styled(ButtonNextLink)`
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin-top: 20px;
`;

const AboutModel = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  cursor: pointer;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main};

    & div > svg {
      fill: ${colors.main100};
    }
  }
`;

const Icon = styled.div`
  margin-left: 5px;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.black100};
  }
`;

export const ShareWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  display: flex;
  z-index: 20;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  ${DropDownMenu} {
    padding-left: 15px;
  }
`;

export const PriceInfo = styled.div`
  width: 280px;
  padding: 15px;
  background: ${colors.white};
  border: 0.5px solid ${colors.gray400};
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  color: ${colors.black};
`;

export const InfoIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11px;
  height: 11px;

  svg {
    path {
      &:first-child {
        stroke: ${(props) => props.theme.black};
      }

      &:not(:first-child) {
        fill: ${(props) => props.theme.blue100};
      }
    }
  }
`;
