import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';
import { colors } from '@/constants/theme';

import { StaticTextAreaProps } from './StaticTextArea.types';

function StaticTextArea({
  label,
  errorMessage,
  isWhiteBackground,
  className,
  ...props
}: StaticTextAreaProps) {
  return (
    <Component className={className}>
      {label && (
        <Label>
          {label} {props.required && <Required>*</Required>}
        </Label>
      )}
      <Area
        isWhiteBackground={isWhiteBackground}
        invalid={!!errorMessage}
        {...props}
      />
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default StaticTextArea;

export const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Label = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.black100};

  ${media.tablet(css`
    font-size: 15px;
    line-height: 18px;
  `)}
`;

export const Required = styled.span`
  color: ${colors.red600};
`;

export const Area = styled.textarea<{
  invalid: boolean;
  isWhiteBackground: StaticTextAreaProps['isWhiteBackground'];
}>`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 144px;
  box-sizing: border-box;
  padding: 14px 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black};
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s;
  resize: none;

  ${ScrollStyles}

  ${media.tablet(css`
    height: 122px;
  `)}
  
  &::placeholder {
    color: ${(props) => props.theme.black100_alpha50};
    opacity: 1;
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${colors.red600};
    `}

  ${({ isWhiteBackground }) =>
    isWhiteBackground &&
    css`
      border: 1px solid ${(props) => props.theme.gray1800};
    `}
`;

export const Error = styled.div`
  position: absolute;
  color: ${colors.red600};
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  bottom: -18px;
`;
