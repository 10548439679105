import React from 'react';
import styled, { css } from 'styled-components';

import PostCard from '@/components/PostCard';
import { media } from '@/utils/mixin';

import { NewsListProps } from './types';

function NewsList({ news }: NewsListProps) {
  if (!news || news.length === 0) {
    return null;
  }

  return (
    <Component>
      {news.map((item, index) => (
        <PostCard key={index} {...item} />
      ))}
    </Component>
  );
}

export default NewsList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}
`;
