import React from 'react';
import styled from 'styled-components';

import CarPortraitView from '@/components/CatalogCarCard/components/CarPortraitView';
import CarLandscapeView from '@/components/CatalogCarCard/components/CarLandscapeView';
import { CatalogCarCardProps } from '@/components/CatalogCarCard/CatalogCarCard.types';

function CatalogCarCard({
  view,
  link,
  name,
  button,
  images,
  badge,
  badgeHint,
  price,
}: CatalogCarCardProps) {
  return (
    <Component>
      {view === 'portrait' ? (
        <CarPortraitView
          view={view}
          button={button}
          images={images}
          link={link}
          name={name}
          badge={badge}
          badgeHint={badgeHint}
          price={price}
        />
      ) : (
        <CarLandscapeView
          view={view}
          button={button}
          images={images}
          link={link}
          name={name}
          badge={badge}
          badgeHint={badgeHint}
          price={price}
        />
      )}
    </Component>
  );
}

export default CatalogCarCard;

const Component = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.gray500};
  height: 100%;
`;
