import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';

import { CreditCardProps } from './types';

function CreditCard({
  title,
  price,
  period,
  description,
  image,
  imageMobile,
  buttonText,
  onSubmit,
}: CreditCardProps) {
  return (
    <Component>
      <Content>
        <ContentInner>
          <Title>{title}</Title>
          <Row>
            <Price>{price}</Price>
            {period && <Period>{period}</Period>}
          </Row>
          <Description>{description}</Description>
        </ContentInner>

        <ButtonWrapper>
          <StyledButton variant="contained" onClick={onSubmit}>
            {buttonText}
          </StyledButton>
        </ButtonWrapper>
      </Content>

      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        alt={title}
      />
    </Component>
  );
}

export default CreditCard;

const Component = styled.div`
  position: relative;
  padding-top: 24.42%;

  ${media.tablet(css`
    padding-top: 41.668%;
  `)}
`;

const Content = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  right: 60px;

  ${media.tablet(css`
    left: 20px;
    right: 20px;
  `)}
`;

const ContentInner = styled.div`
  width: 50%;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: ${(props) => props.theme.creditSlider.text};

  ${media.tablet(css`
    font-size: 3.89vw;
    line-height: 120%;
  `)}
`;

const Row = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Price = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: ${(props) => props.theme.creditSlider.text};

  ${media.tablet(css`
    font-weight: 400;
    font-size: 2.78vw;
    line-height: 120%;
  `)}
`;

const Period = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-left: 45px;
  color: ${(props) => props.theme.creditSlider.text};

  ${media.tablet(css`
    font-weight: 400;
    font-size: 2.78vw;
    line-height: 120%;
    margin-left: 20px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.creditSlider.text};

  ${media.tablet(css`
    font-size: 2.78vw;
    line-height: 120%;
    margin-top: 5px;
  `)}
`;

const ButtonWrapper = styled.div`
  width: 50%;
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 9px;
  `)}
`;

const StyledButton = styled(Button)`
  max-width: 280px;
  padding: 14px 0;

  ${media.tablet(css`
    max-width: 100%;
    height: auto;
    padding: 1.66vw 0;
    font-size: 2.23vw;
    line-height: 14px;
  `)}
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
