import React, { useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import styled, { css } from 'styled-components';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import StaticTextInput from '@/components/StaticTextInput';
import StaticTextArea from '@/components/StaticTextArea';
import { CheckboxAgreement } from '@/components/Checkbox';
import { media } from '@/utils/mixin';
import Loader from '@/components/Loader';
import Button from '@/components/Button';
import Select from '@/components/Select';
import { OptionType } from '@/typings/common';

import {
  getResultServiceFormSecondaryValues,
  initialServiceFormSecondaryValues,
  validationServiceFormSecondarySchema,
} from './helpers';
import {
  ServiceFormSecondaryFieldsSchema,
  ServiceFormSecondaryProps,
} from './types';

export function ServiceFormSecondary({
  title,
  subtitle,
  image,
  buttonTitle,
  imageMobile,
  messagePlaceholder = 'Комментарий',
  fieldsConfig,
  onSubmit,
}: ServiceFormSecondaryProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [isFormCompleted, setFormCompleted] = useState(true);

  const [dealer, setDealer] = useState<OptionType>({
    label: '',
    value: '',
  });
  const [model, setModel] = useState<OptionType>({
    label: '',
    value: '',
  });

  const [userAgreement, setUserAgreement] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    isSubmitting,
    resetForm,
  } = useFormik<ServiceFormSecondaryFieldsSchema>({
    initialValues: initialServiceFormSecondaryValues,
    validationSchema: validationServiceFormSecondarySchema(fieldsConfig),
    onSubmit: async (values, { setSubmitting }) => {
      if (!userAgreement) {
        return;
      }

      setSubmitting(true);

      try {
        await onSubmit?.(
          getResultServiceFormSecondaryValues(values, fieldsConfig)
        ).then(() => {
          resetForm();
          setUserAgreement(false);
          setFormSend(true);

          if (fieldsConfig?.dealers?.length) {
            setDealer({
              label: '',
              value: '',
            });
          }

          if (fieldsConfig?.models?.length) {
            setModel({
              label: '',
              value: '',
            });
          }
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ServiceFormSecondaryFieldsSchema>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  const onSubmitButtonClick = () => {
    if (
      !values.name ||
      !values.phone ||
      !values.dealer?.value ||
      !values.model?.value ||
      !userAgreement
    ) {
      setFormCompleted(false);
    }
  };

  useUpdateEffect(() => {
    if (
      isFormSend &&
      (values.name ||
        values.phone ||
        values.message ||
        (fieldsConfig?.dealers?.length && values.dealer.value) ||
        (fieldsConfig?.models?.length && values.model.value) ||
        userAgreement)
    ) {
      setFormSend(false);
    }

    if (!isFormCompleted) {
      setFormCompleted(true);
    }
  }, [values, userAgreement]);

  useUpdateEffect(() => {
    setFieldValue('dealer', dealer);
  }, [dealer]);

  useUpdateEffect(() => {
    setFieldValue('model', model);
  }, [model]);

  return (
    <Component>
      <Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <Fields>
          {isFormSend && <SuccessMessage>Заявка отправлена</SuccessMessage>}

          <Field>
            <StaticTextInput
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Имя"
              errorMessage={
                errors.name && touched.name ? errors.name : undefined
              }
              isWhiteBackground
            />
          </Field>

          <Field>
            <StaticTextInput
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder="Телефон"
              errorMessage={
                errors.phone && touched.phone ? errors.phone : undefined
              }
              isWhiteBackground
            />
          </Field>

          {fieldsConfig?.models && fieldsConfig.models.length !== 0 && (
            <Field>
              <Select
                label="Модель"
                value={model}
                options={fieldsConfig.models}
                onChange={setModel}
                errorMessage={
                  errors.model?.value && touched.model?.value
                    ? errors.model.value
                    : undefined
                }
              />
            </Field>
          )}

          {fieldsConfig?.dealers && fieldsConfig.dealers.length !== 0 && (
            <Field>
              <Select
                label="Автоцентр"
                value={dealer}
                options={fieldsConfig.dealers}
                onChange={setDealer}
                errorMessage={
                  errors.dealer?.value && touched.dealer?.value
                    ? errors.dealer.value
                    : undefined
                }
              />
            </Field>
          )}

          <TextArea>
            <StaticTextArea
              id="message"
              name="message"
              value={values.message}
              onChange={handleChange}
              placeholder={messagePlaceholder}
              errorMessage={
                errors.message && touched.message ? errors.message : undefined
              }
              isWhiteBackground
            />
          </TextArea>

          <CheckBoxWrapper>
            <CheckboxAgreement
              checked={userAgreement}
              onChange={() => setUserAgreement(!userAgreement)}
            />
          </CheckBoxWrapper>
        </Fields>

        <Bottom>
          <SubmitButton
            type="submit"
            variant="contained"
            onClick={onSubmitButtonClick}
            disabled={isSubmitting}
          >
            {!isSubmitting ? buttonTitle : <Loader color={colors.white} />}
          </SubmitButton>

          {!isFormCompleted && (
            <Error>Заполните, пожалуйста, обязательные поля</Error>
          )}
        </Bottom>
      </Form>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        alt={title}
      />
    </Component>
  );
}

const Component = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column-reverse;
  `)}
`;

const Form = styled.form`
  flex: 1;
  max-width: 100%;
  padding: 60px 125px 60px 60px;
  background: ${(props) => props.theme.gray200};

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    padding: 30px 20px;
  `)}
`;

const Image = styled(Picture)`
  flex: 0 0 500px;
  max-width: 500px;
  min-height: 100%;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    min-height: auto;
    padding-top: 41.668%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-transform: uppercase;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.black};
`;

const Fields = styled.div`
  position: relative;
  margin-top: 30px;
`;

const Field = styled.div`
  &:not(:first-child) {
    margin-top: 30px;
  }
`;

const TextArea = styled.div`
  margin-top: 30px;
`;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  ${media.tablet(css`
    flex-direction: column;
    margin-top: 32px;
  `)}
`;

const SubmitButton = styled(Button)`
  max-width: 250px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const Error = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  margin-left: 15px;
  color: ${colors.red600};

  ${media.tablet(css`
    margin-left: 0;
    margin-top: 15px;
  `)};
`;

const SuccessMessage = styled.span`
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.green};
`;
