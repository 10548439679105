import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import {
  convertThumbnailToPictureImage,
  convertThumbnailToPictureProps,
} from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import ArrowIcon from '@/assets/svg/arrow-right-40px.svg';
import Button from '@/components/Button';
import { SliderWrapper } from '@/components/LandingWidgets';
import {
  Header as SliderWrapperHeader,
  NavButtons as SliderWrapperNavButtons,
} from '@/components/LandingWidgets/components/SliderWrapper';
import { colors } from '@/constants/theme';

import { ModelsBannersProps } from './types';

export function ModelsBanners({ id, models }: ModelsBannersProps) {
  const [currentModel, setCurrentModel] = useState(
    models && models.length > 0 ? models[0] : null
  );
  const isTablet = useMedia(`(max-width: 1259px)`);

  if (!currentModel) {
    return null;
  }

  const slidesPerView = !isTablet ? 2 : 1;

  const { image, mobileImage, name, link, linkText, buttonText, onClick } =
    currentModel;

  return (
    <Component>
      <ContentContainer>
        <Background
          loading="lazy"
          desktop={convertThumbnailToPictureImage(image)}
          laptop={convertThumbnailToPictureImage(image)}
          tabletSmall={convertThumbnailToPictureImage(mobileImage ?? image)}
          mobile={convertThumbnailToPictureImage(mobileImage ?? image)}
          alt={name}
        />

        <Content>
          <Header>
            <Title>{name}</Title>
            <StyledLink to={link}>
              {linkText} <ArrowIcon />
            </StyledLink>
          </Header>
          <Bottom>
            <Slider>
              <SliderWrapper
                id={id}
                options={{
                  slidesPerView,
                  spaceBetween: 20,
                  withoutPagination: true,
                }}
              >
                {models.map(({ previewImage, name }, index) => (
                  <SwiperSlide key={index}>
                    <Preview onClick={() => setCurrentModel(models[index])}>
                      <PreviewImage
                        loading="lazy"
                        {...convertThumbnailToPictureProps(previewImage)}
                      />
                      <PreviewModel>{name}</PreviewModel>
                    </Preview>
                  </SwiperSlide>
                ))}
              </SliderWrapper>
            </Slider>

            <ButtonWrapper>
              <Button variant="contained" onClick={onClick}>
                {buttonText}
              </Button>
            </ButtonWrapper>
          </Bottom>
        </Content>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  position: relative;
  padding-top: 51.283%;

  ${media.tablet(css`
    padding-top: initial;
    height: 800px;
  `)}
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 110px 40px 40px;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;

  ${media.tablet(css`
    padding: 60px 20px 40px;
  `)}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: ${colors.black};

  ${media.tablet(css`
    font-size: 28px;
    line-height: 36px;
  `)}
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  color: ${colors.black};

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  svg {
    width: 13px;
    height: 13px;
    margin-left: 15px;
    fill: ${colors.main};
  }

  &:hover {
    opacity: 0.7;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column-reverse;
  `)}
`;

const Slider = styled.div`
  padding: 0 50px;
  width: 100%;
  max-width: 600px;

  ${media.tablet(css`
    padding: 0 30px;
    max-width: 320px;
    margin-top: 60px;
  `)}

  ${SliderWrapperHeader} {
    width: calc(100% + 100px);
    top: 50%;
    transform: translateY(-50%);
    margin: 0 -50px;

    ${media.tablet(css`
      width: calc(100% + 60px);
      margin: 0 -30px;
    `)}

    ${SliderWrapperNavButtons} {
      justify-content: space-between;
    }
  }
`;

const Preview = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

const PreviewImage = styled(Picture)`
  width: 180px;
  height: 100px;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const PreviewModel = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 190px;

  ${media.tablet(css`
    max-width: 170px;
  `)}
`;
