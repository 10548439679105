import React from 'react';
import styled, { css } from 'styled-components';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

import { media } from '@/utils/mixin';
import { getYouTubeId } from '@/utils/common';

import { VideoProps } from './types';

function Video({ link }: VideoProps) {
  return (
    <Component>
      <LiteYouTubeEmbed id={getYouTubeId(link)} title="YouTube Embed" />
    </Component>
  );
}

export default Video;

const Component = styled.div`
  position: relative;
  padding-top: 60.001%;

  article {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  ${media.tablet(css`
    padding-top: 65.627%;
  `)}
`;
