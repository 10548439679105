import React from 'react';

import { useMedia } from '@tager/web-core';

import { ModelsProps } from './types';
import ModelsList from './components/ModelsList';
import ModelsSlider from './components/ModelsSlider';

export function Models({ id, models }: ModelsProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  if (!models || models.length === 0) {
    return null;
  }

  return !isTablet ? (
    <ModelsList models={models} />
  ) : (
    <ModelsSlider id={id} models={models} />
  );
}
