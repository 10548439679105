import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { ButtonNextLink } from '@/components/Button';
import { media } from '@/utils/mixin';

import { ServiceBannerProps } from './types';

function ServiceBanner({
  image,
  mobileImage,
  title,
  buttonText,
  buttonLink,
}: ServiceBannerProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(mobileImage ?? image)}
        mobile={convertThumbnailToPictureImage(mobileImage ?? image)}
        alt={title}
      />

      <Content>
        <ContentInner>
          <Title>{title}</Title>

          <ButtonWrapper>
            <ButtonNextLink variant="contained" to={buttonLink}>
              {buttonText}
            </ButtonNextLink>
          </ButtonWrapper>
        </ContentInner>
      </Content>
    </Component>
  );
}

export default ServiceBanner;

const Component = styled.div`
  position: relative;
  padding-top: 153.847%;

  ${media.mobile(css`
    padding-top: initial;
    height: 800px;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 60px 40px;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;

  ${media.tablet(
    css`
      padding: 90px 20px;
    `
  )}
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet(css`
    align-items: center;
  `)}
`;

const Title = styled.p`
  font-size: 35px;
  line-height: 45px;
  text-transform: uppercase;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  max-width: 200px;
  margin-top: 20px;
`;
