import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';

import { TextContentProps } from './types';

export function TextContent({ text }: TextContentProps) {
  return <Component dangerouslySetInnerHTML={{ __html: text }} />;
}

const Component = styled.div`
  color: ${(props) => props.theme.black};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    margin: 20px 0;

    ${media.tablet(css`
      font-size: 16px;
      line-height: 21px;
      margin: 10px 0;
    `)}
  }

  p {
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 1.5vw;

    ${media.tablet(css`
      margin: 0 0 2vw;
    `)}
  }

  ul,
  ol {
    margin: 20px 0 20px 17px;

    li {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 5px;
      vertical-align: top;
      padding-left: 0.6vw;

      &::marker {
        color: ${colors.main};
      }
    }
  }

  ul {
    list-style-type: square;
  }

  ol {
    list-style-type: decimal;
  }

  figure {
    margin: 2vw auto;
    overflow: auto;

    ${ScrollStyles}

    table {
      width: 100%;
      min-width: 600px;
      border-collapse: collapse;
      border: 1px solid ${colors.gray400};

      tbody {
        tr {
          th,
          td {
            padding: 5px;
            border: 1px solid ${colors.gray400};
          }
        }
      }
    }

    &.image {
      ${media.tablet(css`
        width: auto !important;
        margin: 2vw -20px !important;
      `)}
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  blockquote {
    font-size: 16px;
    line-height: 21px;
    padding: 30px;
    margin: 20px 0;
    background: ${(props) => props.theme.gray_1};

    p {
      margin: 0;
    }

    ${media.tablet(css`
      padding: 20px;
    `)}
  }

  a {
    color: ${colors.main};
  }
`;
