import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ServicesBannersProps } from './types';
import ServicesBannersList from './components/ServicesBannersList';
import ServicesBannersSlider from './components/ServicesBannersSlider';

export function ServicesBanners({ id, services }: ServicesBannersProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!services || services.length === 0) {
    return null;
  }

  return (
    <Component>
      {!isMobile ? (
        <ServicesBannersList services={services} />
      ) : (
        <ServicesBannersSlider id={id} services={services} />
      )}
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;
