import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

import { PostCardProps } from './types';

function PostCard({ image, title, url, description, date }: PostCardProps) {
  return (
    <Component to={url}>
      <Image
        loading="lazy"
        {...convertThumbnailToPictureProps(image)}
        alt={title}
      />

      <Content>
        <Top>
          <Text>{title}</Text>
          {description && <Description>{description}</Description>}
        </Top>

        {date && <Date>{date}</Date>}
      </Content>
    </Component>
  );
}

export default PostCard;

const Image = styled(Picture)`
  position: relative;
  padding-top: 65.825%;

  picture,
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  transition: 0.3s;
  color: ${(props) => props.theme.black};
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};
`;

const Date = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  color: ${(props) => props.theme.gray700};
`;

export const Component = styled(Link)`
  display: flex;
  flex-direction: column;

  &:hover {
    ${Text} {
      color: ${colors.main};
    }
  }
`;
