import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { GuaranteeBannerProps } from './types';

export function GuaranteeBanner({
  image,
  imageMobile,
  text,
}: GuaranteeBannerProps) {
  return (
    <Component>
      {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}

      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        alt={text}
      />
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 43px 60px;
  min-height: 150px;

  ${media.tablet(css`
    padding: 22px 20px;
    min-height: 100px;
  `)}

  ${media.mobile(css`
    min-height: 150px;
  `)}
`;

const Text = styled.div`
  z-index: 1;
  position: relative;
  font-size: 25px;
  line-height: 32px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 21px;
  `)}

  ${media.mobile(css`
    max-width: 50%;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
