import React from 'react';
import styled, { css } from 'styled-components';
// @ts-ignore
import { IMaskInput } from 'react-imask';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { StaticTextInputStyles } from './StaticTextInput.styles';
import { StaticTextInputProps } from './StaticTextInput.types';

function StaticTextInput({
  label,
  requiredField,
  errorMessage,
  badge,
  isWhiteBackground,
  mask,
  ...props
}: StaticTextInputProps) {
  return (
    <Component>
      {label && (
        <Label>
          {label} {requiredField && <Required>*</Required>}
        </Label>
      )}

      {!mask ? (
        <Input
          isWhiteBackground={isWhiteBackground}
          invalid={!!errorMessage}
          {...props}
        />
      ) : (
        <InputMasked
          isWhiteBackground={isWhiteBackground}
          invalid={!!errorMessage}
          mask={mask}
          {...props}
        />
      )}

      {badge && <Badge>{badge}</Badge>}
      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default StaticTextInput;

export const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.black100};

  ${media.tablet(css`
    font-size: 15px;
    line-height: 18px;
  `)}
`;

export const Badge = styled.div`
  display: inline-flex;
  position: absolute;
  right: 24px;
  bottom: 16px;
  pointer-events: none;

  svg {
    height: 20px;

    path {
      fill: ${(props) => props.theme.black100_alpha50};
    }
  }

  ${media.tablet(css`
    bottom: 14px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    line-height: 21px;
  `)}
`;

export const Required = styled.span`
  color: ${colors.red600};
`;

export const Input = styled.input<{
  invalid: boolean;
  isWhiteBackground: StaticTextInputProps['isWhiteBackground'];
}>`
  ${StaticTextInputStyles}
`;

export const InputMasked = styled(IMaskInput)<{
  invalid: boolean;
  isWhiteBackground: StaticTextInputProps['isWhiteBackground'];
}>`
  ${StaticTextInputStyles}
`;

export const Error = styled.div`
  position: absolute;
  color: ${colors.red600};
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  bottom: -18px;
`;
