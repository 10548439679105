import React from 'react';
import styled, { css } from 'styled-components';

import AboutCardPrimaryBenefit from '@/components/LandingWidgets/components/AboutCardPrimary/components/AboutCardPrimaryBenefit';

import { AboutCardPrimaryBenefitListProps } from './types';

function AboutCardPrimaryBenefitList({
  benefits,
}: AboutCardPrimaryBenefitListProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component benefitsCount={benefits.length}>
      {benefits.map(({ image, text }, index) => (
        <AboutCardPrimaryBenefit key={index} image={image} text={text} />
      ))}
    </Component>
  );
}

export default AboutCardPrimaryBenefitList;

const Component = styled.div<{ benefitsCount: number }>`
  display: grid;
  gap: 25px;

  ${({ benefitsCount }) =>
    benefitsCount > 5
      ? css`
          grid-template-columns: repeat(5, 1fr);
        `
      : css`
          grid-template-columns: repeat(${benefitsCount}, 1fr);
        `}
`;
