import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import Button from '@/components/Button';
import { colors } from '@/constants/theme';

import { TestDriveCardProps } from './types';

export function TestDriveCard({
  title,
  description,
  image,
  imageMobile,
  buttonText,
  onSubmit,
}: TestDriveCardProps) {
  return (
    <Component>
      <Content>
        <ContentInner>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </ContentInner>

        {buttonText && (
          <ButtonWrapper>
            <StyledButton variant="contained" onClick={onSubmit}>
              {buttonText}
            </StyledButton>
          </ButtonWrapper>
        )}
      </Content>

      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        alt={title}
      />
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding-top: 25%;

  ${media.tablet(css`
    padding-top: 41.668%;
  `)}
`;

const Content = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  right: 60px;

  ${media.tablet(css`
    left: 20px;
    right: 20px;
  `)}
`;

const ContentInner = styled.div`
  width: 50%;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 3.89vw;
    line-height: 120%;
    margin-top: 5px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 2.238vw;
    line-height: 120%;
    margin-top: 5px;
  `)}
`;

const ButtonWrapper = styled.div`
  width: 50%;
  margin-top: 26px;

  ${media.tablet(css`
    margin-top: 13px;
  `)}
`;

const StyledButton = styled(Button)`
  max-width: 260px;
  padding: 14px 0;

  ${media.tablet(css`
    max-width: 100%;
    height: auto;
    padding: 1.66vw 0;
    font-size: 2.23vw;
    line-height: 14px;
  `)}
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
