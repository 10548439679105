import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { DepartmentListProps } from './types';
import DepartmentCard from './components/DepartmentCard';

export function DepartmentList({ departments }: DepartmentListProps) {
  if (!departments || departments.length === 0) {
    return null;
  }

  return (
    <Component>
      {departments.map(({ name, phones, workTimes }, index) => (
        <DepartmentCard
          key={index}
          name={name}
          phones={phones}
          workTimes={workTimes}
        />
      ))}
    </Component>
  );
}

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
