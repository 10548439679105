import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { VideoBannerProps } from './types';

export function VideoBanner({
  className,
  video,
  title,
  subtitle,
}: VideoBannerProps) {
  return (
    <Component className={className}>
      <Container>
        <Video
          src={video + '#t=0.1'}
          preload="metadata"
          autoPlay
          muted
          loop
          playsInline
        />

        <Content>
          <Inner>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Inner>
        </Content>
      </Container>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  padding-top: 29.033%;

  ${media.tablet(css`
    padding-top: 41.668%;
  `)}
`;

const Video = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  padding: 40px;

  ${media.tablet(
    css`
      padding: 20px;
    `
  )}
`;

const Inner = styled.div`
  max-width: 650px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 64px;
  line-height: 74px;
  text-align: center;
  margin: 0;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 30px;
    line-height: 40px;
  `)}
`;

const Subtitle = styled.p`
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin-top: 10px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;
