import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import MarkerIcon from '@/assets/svg/dealer-web/marker.svg';
import CloseIcon from '@/assets/svg/black.svg';
import { colors } from '@/constants/theme';

import { MarkerProps } from './types';

const Marker = ({ name, address }: MarkerProps) => {
  const [isPopupOpened, setPopupOpened] = useState(false);

  const handlePopupOpen = (event: React.SyntheticEvent) => {
    setPopupOpened(!isPopupOpened);
    event.stopPropagation();
  };

  return (
    <Component>
      <StyledMarkerIcon onClick={handlePopupOpen} />
      <Popup isPopupOpened={isPopupOpened}>
        <StyledCloseIcon onClick={handlePopupOpen} />
        <Name>{name}</Name>
        <Address>{address}</Address>
      </Popup>
    </Component>
  );
};

export default Marker;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const Popup = styled.div<{
  isPopupOpened: boolean;
}>`
  z-index: 100;
  position: absolute;
  min-width: 230px;
  bottom: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  background: ${(props) => props.theme.white};
  visibility: hidden;

  &:after {
    content: '';
    background: ${(props) => props.theme.white};
    height: 15px;
    position: absolute;
    left: 50%;
    bottom: -13px;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
  }

  ${({ isPopupOpened }) =>
    isPopupOpened &&
    css`
      visibility: visible;
    `}
`;

const Name = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.main};
`;

const Address = styled.span`
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  color: ${(props) => props.theme.gray2200};
`;

const StyledMarkerIcon = styled(MarkerIcon)`
  position: absolute;
  top: -50px;

  path {
    fill: ${colors.main};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  opacity: 0.6;
  width: 16px;
  height: 16px;
  top: 5px;
  right: 5px;

  &:hover {
    opacity: 1;
  }
`;
