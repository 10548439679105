import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useUpdateEffect } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { CatalogCardSliderProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import CarCurrentState from '@/components/CarCurrentState';

function MobileCatalogCardSlider({
  link,
  images,
  badge,
  badgeHint,
  alt,
}: CatalogCardSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useUpdateEffect(() => {
    setActiveSlideIndex(0);
  }, []);

  return (
    <Component>
      <Swiper
        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
      >
        {images && images.length > 0
          ? images.slice(0, 4).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Slide to={link}>
                    <StyledPicture
                      {...convertThumbnailToPictureProps(item)}
                      loading="lazy"
                      alt={`${alt ?? 'catalog card image'} #${index + 1}`}
                    />
                  </Slide>
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>

      {images.length > 1 && (
        <PaginationList>
          {images.slice(0, 4).map((item: any, index: number) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
              />
            );
          })}
        </PaginationList>
      )}

      {badge && (
        <CarCurrentStateWrapper>
          <CarCurrentState text={badge} info={badgeHint} />
        </CarCurrentStateWrapper>
      )}
    </Component>
  );
}

export default MobileCatalogCardSlider;

const Component = styled.div`
  position: relative;
  background-color: #efefef;
  border-radius: 8px 8px 0 0;
  padding-top: 60%;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const Slide = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const StyledPicture = styled(Picture)`
  position: static !important;

  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: contain;
  }
`;

const PaginationList = styled.div`
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
`;

const PaginationListItem = styled.div<{ isActive: boolean }>`
  content: '';
  width: 40px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;
  
  &:not(:last-child) {
    margin-right: 5px;
  }
  
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}
  }
`;

const CarCurrentStateWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 5px;
  bottom: 0;
  transform: translateY(50%);
`;
