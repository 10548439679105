import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { TitleProps } from './types';

export function Title({ title }: TitleProps) {
  return <Component>{title}</Component>;
}

export const Component = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;
