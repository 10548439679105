import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { SliderWrapper } from '@/components/LandingWidgets';
import PostCard, {
  Component as PostCardComponent,
} from '@/components/PostCard';

import { NewsSliderProps } from './types';

function NewsSlider({ id, news }: NewsSliderProps) {
  if (!news || news.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper id={id} options={{ spaceBetween: 20, withoutNavs: true }}>
        {news.map((item, index) => (
          <SwiperSlide key={index}>
            <PostCard {...item} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default NewsSlider;

const Component = styled.div`
  .swiper-slide {
    height: auto;
  }

  ${PostCardComponent} {
    height: 100%;
  }
`;
