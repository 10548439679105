import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';

import { AboutCardSecondaryBenefitProps } from './types';

export function AboutCardSecondaryBenefit({
  image,
  title,
  description,
}: AboutCardSecondaryBenefitProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        {...convertThumbnailToPictureProps(image)}
        alt={title}
      />
      <Title>{title}</Title>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Component>
  );
}

export const Component = styled.div`
  padding: 30px 20px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray2100};
`;

const Image = styled(Picture)`
  width: 120px;
  height: 120px;
  margin: 0 auto;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
  color: ${(props) => props.theme.black};
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme.gray2000};
`;
