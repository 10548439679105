import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { AboutCardPrimaryBenefitType } from '@/components/LandingWidgets/components/AboutCardPrimary/types';
import Picture from '@/components/Picture';

function AboutCardPrimaryBenefit({ image, text }: AboutCardPrimaryBenefitType) {
  return (
    <Component>
      <Image
        loading="lazy"
        {...convertThumbnailToPictureProps(image)}
        alt={text}
      />
      <Text>{text}</Text>
    </Component>
  );
}

export default AboutCardPrimaryBenefit;

export const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled(Picture)`
  picture,
  img {
    width: 70px;
    height: 70px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-top: 20px;
  color: ${(props) => props.theme.gray2000};
`;
