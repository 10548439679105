import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { SliderWrapper } from '@/components/LandingWidgets';
import CatalogCarCard from '@/components/CatalogCarCard';
import { ButtonNextLink } from '@/components/Button';

import SelectionCarCard, {
  Component as SelectionCarCardComponent,
} from './components/SelectionCarCard';
import { CarsCatalogListProps } from './types';

export function CarsCatalogList({
  id,
  cars,
  selectionCarCard,
  options,
  buttonTitle,
  buttonLink,
}: CarsCatalogListProps) {
  if (!cars || cars.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper id={id} options={options}>
        {cars.map((car, index) => (
          <SwiperSlide
            key={index}
            className={options?.isNoSwiping ? options?.noSwipingClass : ''}
          >
            <CatalogCarCard {...car} />
          </SwiperSlide>
        ))}

        <SwiperSlide
          className={options?.isNoSwiping ? options?.noSwipingClass : ''}
        >
          <SelectionCarCard {...selectionCarCard} />
        </SwiperSlide>
      </SliderWrapper>

      {buttonTitle && buttonLink && (
        <ButtonWrapper>
          <StyledButtonLink variant="outlined" to={buttonLink}>
            {buttonTitle}
          </StyledButtonLink>
        </ButtonWrapper>
      )}
    </Component>
  );
}

const Component = styled.div`
  .swiper-slide {
    height: auto;
  }

  ${SelectionCarCardComponent} {
    height: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 300px;
`;
