import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ButtonNextLink } from '@/components/Button';

import AboutCardSecondaryBenefitList from './components/AboutCardSecondaryBenefitList';
import AboutCardSecondaryBenefitSlider from './components/AboutCardSecondaryBenefitSlider';
import { AboutCardSecondaryProps } from './types';

export function AboutCardSecondary({
  id,
  title,
  benefits,
  description,
  buttonTitle,
  buttonLink,
}: AboutCardSecondaryProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  return (
    <Component>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      <Benefits>
        {!isTablet ? (
          <AboutCardSecondaryBenefitList benefits={benefits} />
        ) : (
          <AboutCardSecondaryBenefitSlider id={id} benefits={benefits} />
        )}
      </Benefits>
      {buttonTitle && buttonLink && (
        <ButtonWrapper>
          <StyledButtonLink variant="outlined" to={buttonLink}>
            {buttonTitle}
          </StyledButtonLink>
        </ButtonWrapper>
      )}
    </Component>
  );
}

const Component = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 22px;
    line-height: 29px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 21px;
  max-width: 970px;
  text-align: center;
  margin: 20px auto 0;
  color: ${(props) => props.theme.gray2000};
`;

const Benefits = styled.div`
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 300px;
`;
