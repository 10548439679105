import React from 'react';
import styled from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import Button from '@/components/Button';

import { SelectionCarCardProps } from './types';

function SelectionCarCard({
  image,
  title,
  description,
  buttonTitle,
  onClick,
}: SelectionCarCardProps) {
  return (
    <Component>
      <Image {...convertThumbnailToPictureProps(image)} alt={title} />
      <Content>
        <Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Header>

        <StyledButton variant="contained" onClick={onClick}>
          {buttonTitle}
        </StyledButton>
      </Content>
    </Component>
  );
}

export default SelectionCarCard;

export const Component = styled.div`
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray500};
  border-radius: 8px;
`;

const Image = styled(Picture)`
  padding-top: 60%;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0 0;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Header = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: ${(props) => props.theme.black};
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 15px;
  color: ${(props) => props.theme.blue100};
`;

const StyledButton = styled(Button)`
  font-weight: 700;
  margin: 18px 0 50px;
`;
