import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { SloganPrimaryProps } from './types';

export function SloganPrimary({ text }: SloganPrimaryProps) {
  return <Component dangerouslySetInnerHTML={{ __html: text }} />;
}

export const Component = styled.div`
  position: relative;
  padding: 30px 50px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  background: ${(props) => props.theme.gray_1};
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    padding: 20px;
    font-size: 16px;
    line-height: 21px;
  `)}

  b,
  strong {
    font-weight: 700;
    color: ${colors.main};
  }
`;
