import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ButtonNextLink } from '@/components/Button';

import { BannerProps } from './types';

export function Banner({
  image,
  mobileImage,
  video,
  title,
  buttonText,
  buttonLink,
  subtitle,
}: BannerProps) {
  return (
    <Component>
      <ContentContainer>
        {image && (
          <Image
            loading="lazy"
            desktop={convertThumbnailToPictureImage(image)}
            laptop={convertThumbnailToPictureImage(image)}
            tabletSmall={convertThumbnailToPictureImage(mobileImage ?? image)}
            mobile={convertThumbnailToPictureImage(mobileImage ?? image)}
            alt={title}
          />
        )}

        {video && (
          <Video
            src={video + '#t=0.1'}
            preload="metadata"
            autoPlay
            muted
            loop
            playsInline
          />
        )}

        <Content>
          <Text>{title}</Text>

          {subtitle && <Text>{subtitle}</Text>}

          <ButtonWrapper>
            <ButtonNextLink variant="contained" to={buttonLink}>
              {buttonText}
            </ButtonNextLink>
          </ButtonWrapper>
        </Content>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  position: relative;
  padding-top: 51.283%;

  ${media.tablet(css`
    padding-top: initial;
    height: 600px;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Video = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 143px 40px;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;

  ${media.tablet(css`
    padding: 50px 20px;
  `)}
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: ${colors.white};

  &:not(:first-child) {
    margin-top: 10px;
  }

  ${media.tablet(css`
    font-size: 28px;
    line-height: 36px;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 170px;
  margin-top: 20px;
`;
