import React from 'react';
import styled from 'styled-components';

import ServiceBanner from '../ServiceBanner';

import { ServicesBannersListProps } from './types';

function ServicesBannersList({ services }: ServicesBannersListProps) {
  if (!services || services.length === 0) {
    return null;
  }

  return (
    <Component>
      {services.map((service, index) => (
        <ServiceBanner key={index} {...service} />
      ))}
    </Component>
  );
}

export default ServicesBannersList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;
