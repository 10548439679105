import React from 'react';
import GoogleMapReact from 'google-map-react';

import { getMapProps } from '@/utils/map';

import { GoogleMapProps } from './types';
import Marker from './components/Marker';

function GoogleMap({ markers }: GoogleMapProps) {
  if (!markers || markers.length === 0) {
    return null;
  }

  const mapProps = getMapProps(
    markers.map(({ latitude, longitude }) => ({
      latitude,
      longitude,
    }))
  );

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
      }}
      center={mapProps.center}
      defaultZoom={mapProps.zoom}
    >
      {markers.map(({ address, name, latitude, longitude }, index) => (
        <Marker
          key={index}
          address={address}
          name={name}
          lat={latitude}
          lng={longitude}
        />
      ))}
    </GoogleMapReact>
  );
}

export default GoogleMap;
