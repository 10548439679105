import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { SloganSecondaryProps } from './types';

export function SloganSecondary({ text }: SloganSecondaryProps) {
  return (
    <Component>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Component>
  );
}

const Component = styled.div`
  padding: 125px 40px;
  display: flex;
  justify-content: center;
  background: ${colors.black};

  ${media.tablet(
    css`
      padding: 125px 20px;
    `
  )}
`;

const Text = styled.div`
  max-width: 735px;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 28px;
    line-height: 36px;
  `)}

  b,
  strong {
    font-weight: 400;
    color: ${colors.main};
  }
`;
