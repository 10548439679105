import * as Yup from 'yup';
import { omit } from 'lodash';

import {
  ServiceFormSecondaryFieldsConfig,
  ServiceFormSecondaryFieldsSchema,
} from './types';

export const getResultServiceFormSecondaryValues = (
  values: ServiceFormSecondaryFieldsSchema,
  fieldsConfig?: ServiceFormSecondaryFieldsConfig
) => {
  const paths: Array<keyof ServiceFormSecondaryFieldsSchema> = [];

  if (!fieldsConfig?.dealers?.length) {
    paths.push('dealer');
  }

  if (!fieldsConfig?.models?.length) {
    paths.push('model');
  }

  return omit(values, paths) as ServiceFormSecondaryFieldsSchema;
};

export const initialServiceFormSecondaryValues: ServiceFormSecondaryFieldsSchema =
  {
    name: '',
    phone: '',
    dealer: { label: '', value: '' },
    model: { label: '', value: '' },
    message: '',
  };

export const validationServiceFormSecondarySchema = (
  fieldsConfig?: ServiceFormSecondaryFieldsConfig
): Yup.SchemaOf<ServiceFormSecondaryFieldsSchema> => {
  return Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().required('Укажите номер телефона'),
    model: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when([], {
      is: () => fieldsConfig?.models && fieldsConfig.models.length !== 0,
      then: Yup.object({
        label: Yup.string().required('Выберите модель'),
        value: Yup.string().required('Выберите модель'),
      }),
      otherwise: Yup.object({
        label: Yup.string().notRequired(),
        value: Yup.string().notRequired(),
      }),
    }),
    dealer: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when([], {
      is: () => fieldsConfig?.dealers && fieldsConfig.dealers.length !== 0,
      then: Yup.object({
        label: Yup.string().required('Выберите автоцентр'),
        value: Yup.string().required('Выберите автоцентр'),
      }),
      otherwise: Yup.object({
        label: Yup.string().notRequired(),
        value: Yup.string().notRequired(),
      }),
    }),
    message: Yup.string(),
  }) as Yup.SchemaOf<ServiceFormSecondaryFieldsSchema>;
};
