import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { SliderWrapper } from '@/components/LandingWidgets';
import {
  Component as SliderWrapperComponent,
  PaginationWrapper as SliderWrapperPaginationWrapper,
} from '@/components/LandingWidgets/components/SliderWrapper';

import ServiceBanner from '../ServiceBanner';

import { ServicesBannersSliderProps } from './types';

function ServicesBannersSlider({ id, services }: ServicesBannersSliderProps) {
  if (!services || services.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper id={id} options={{ withoutNavs: true }}>
        {services.map((service, index) => (
          <SwiperSlide key={index}>
            <ServiceBanner {...service} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default ServicesBannersSlider;

const Component = styled.div`
  ${SliderWrapperComponent} {
    padding-bottom: 0;
  }

  ${SliderWrapperPaginationWrapper} {
    bottom: 40px;
  }
`;
