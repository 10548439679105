import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

import { SliderWrapper } from '@/components/LandingWidgets';
import {
  AboutCardSecondaryBenefit,
  Component as AboutCardSecondaryBenefitComponent,
} from '@/components/LandingWidgets/components/AboutCardSecondary/components/AboutCardSecondaryBenefit';

import { AboutCardSecondaryBenefitSliderProps } from './types';

function AboutCardSecondaryBenefitSlider({
  id,
  benefits,
}: AboutCardSecondaryBenefitSliderProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper id={id} options={{ spaceBetween: 20, withoutNavs: true }}>
        {benefits.map((props, index) => (
          <SwiperSlide key={index}>
            <AboutCardSecondaryBenefit {...props} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default AboutCardSecondaryBenefitSlider;

const Component = styled.div`
  .swiper-slide {
    height: auto;
  }

  ${AboutCardSecondaryBenefitComponent} {
    height: 100%;
  }
`;
