import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import { SliderWrapper } from '@/components/LandingWidgets';
import {
  ModelCard,
  Component as ModelCardComponent,
} from '@/components/LandingWidgets/components/Models/components/ModelCard';

import { ModelsSliderProps } from './types';

function ModelsSlider({ id, models }: ModelsSliderProps) {
  const isTablet = useMedia('(max-width: 1259px)');

  if (!models || models.length === 0) {
    return null;
  }

  const isWithoutNavs = models.length === 1;
  const isWithoutPagination = !isTablet ? true : models.length === 1;
  const isNoSwiping = models.length === 1;

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          spaceBetween: 20,
          noSwipingClass: 'swiper-no-swiping',
          withoutNavs: isWithoutNavs,
          withoutPagination: isWithoutPagination,
        }}
      >
        {models.map((model, index) => (
          <SwiperSlide
            key={index}
            className={isNoSwiping ? 'swiper-no-swiping' : ''}
          >
            <ModelCard {...model} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default ModelsSlider;

const Component = styled.div`
  .swiper-slide {
    height: auto;
  }

  ${ModelCardComponent} {
    height: 100%;
  }
`;
