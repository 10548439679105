import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

import { DealerMapProps } from './types';
import Map from './components/Map';
import DealerCard from './components/DealerCard';

export function DealerMap({
  dealers,
  mapImages,
  mapType = 'google',
}: DealerMapProps) {
  if (!dealers || dealers.length === 0) {
    return null;
  }

  const markers = dealers.map(({ name, address, latitude, longitude }) => ({
    name,
    address,
    latitude: latitude ?? 0,
    longitude: longitude ?? 0,
  }));

  return (
    <Component>
      <MapWrapper>
        {!mapImages || !mapImages.desktop || !mapImages.mobile ? (
          <Map mapType={mapType} markers={markers} />
        ) : (
          <MapImage
            loading="lazy"
            desktop={convertThumbnailToPictureImage(mapImages.desktop)}
            laptop={convertThumbnailToPictureImage(mapImages.desktop)}
            tabletSmall={convertThumbnailToPictureImage(mapImages.mobile)}
            mobile={convertThumbnailToPictureImage(mapImages.mobile)}
            alt="dealer map image"
          />
        )}
      </MapWrapper>

      <DealerCardWrapper>
        {dealers.map(
          ({ name, legalName, address, services, phones, socials }, index) => (
            <DealerItem key={index}>
              <DealerCard
                name={name}
                legalName={legalName}
                address={address}
                services={services}
                phones={phones}
                socials={socials}
              />
            </DealerItem>
          )
        )}
      </DealerCardWrapper>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 60px;

  ${media.tablet(css`
    padding: 0;
  `)}
`;

const MapWrapper = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  ${media.tablet(css`
    position: relative;
    padding-top: 69.445%;
    overflow: hidden;
  `)}
`;

const MapImage = styled(Picture)`
  position: absolute;
  inset: 0;

  picture,
  img {
    width: 100%;
    height: 100%;

    ${media.tablet(css`
      width: 1259px;
      height: 875px;
    `)}
  }

  img {
    object-fit: cover;
  }
`;

const DealerCardWrapper = styled.div`
  z-index: 1;
  position: relative;
  max-width: 595px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const DealerItem = styled.div`
  &:not(:first-child) {
    margin-top: 30px;

    ${media.tablet(css`
      margin-top: -20px;
    `)}
  }
`;
