import React from 'react';
import styled from 'styled-components';

import { ModelCard } from '@/components/LandingWidgets/components/Models/components/ModelCard';

import { ModelsListProps } from './types';

function ModelsList({ models }: ModelsListProps) {
  if (!models || models.length === 0) {
    return null;
  }

  return (
    <Component>
      {models.map((model, index) => (
        <ModelCardWrapper key={index}>
          <ModelCard {...model} />
        </ModelCardWrapper>
      ))}
    </Component>
  );
}

export default ModelsList;

const Component = styled.div``;

const ModelCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
`;
